<template>
  <div class="sourceManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name == 'sourceManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="成考生源管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" class="demo-form-inline" label-width="80px">
              <el-form-item label="学校">
                <el-select
                  v-model="formInline.schoolId"
                  placeholder="请选择学校"
                  @change="changeSchool"
                  filterable
                >
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input
                  v-model="formInline.name"
                  placeholder="输入学生姓名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="身份证号">
                <el-input
                  v-model="formInline.cardId"
                  placeholder="输入学生身份证号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="招生批次">
                <el-select
                  v-model="formInline.admissionBatch"
                  @change="changeAdmissionBatch"
                  @focus="getGoSchoolData"
                  filterable
                  placeholder="选择招生批次"
                >
                  <el-option
                    v-for="(item, index) in schoolBatchData"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="招生层次">
                <el-select
                  v-model="formInline.eduCategory"
                  placeholder="选择招生层次"
                  @change="changeEduCategory"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="(item, key, index) in $common.getEduCategory"
                    :key="index"
                    :label="item"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="招生专业">
                <el-select
                  v-model="formInline.majorId"
                  placeholder="选择专业"
                  @focus="getMajorDataEvent"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in majorData"
                    :key="item.majorId"
                    :label="item.majorName"
                    :value="item.majorId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教学点">
                <el-select
                  v-model="formInline.stationId"
                  placeholder="选择教学点"
                  @focus="hasAbmEvent"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in allStationData"
                    :key="item.stationId"
                    :label="item.stationsName"
                    :value="item.stationsId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="班级">
                <el-select
                  v-model="formInline.aidClassId"
                  placeholder="选择班级"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in allClassData"
                    :key="item.classId"
                    :label="item.className"
                    :value="item.classId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" plain @click="onSubmit"
                >搜索</el-button
              >
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <el-table
            :data="tableData"
            border
            stripe
            style="width: 100%"
            align="center"
            @selection-change="handleSelectionChange"
          >
            <!-- <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55"
            >
            </el-table-column> -->
            <el-table-column
              prop="schoolName"
              label="学校"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              width="100"
            >
              <!-- <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis" v-if="scope.row.disputeFlag==0?true:false">{{scope.row.name}}</div>
                </el-tooltip>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="loginNum"
              label="助学账号"
              align="center"
              width="140"
            >
            </el-table-column>
            <el-table-column
              prop="cardId"
              label="身份证号"
              align="center"
              width="160"
            >
            </el-table-column>
            <el-table-column prop="stationName" label="教学点" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.disputeFlag == 0 ? true : false">{{
                  scope.row.stationName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="admissionBatch"
              label="招生批次"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.disputeFlag == 0 ? true : false">{{
                  scope.row.admissionBatch
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="eduCategoryName"
              label="招生层次"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.disputeFlag == 0 ? true : false">{{
                  scope.row.eduCategoryName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="majorName"
              label="招生专业"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.disputeFlag == 0 ? true : false">{{
                  scope.row.majorName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="班级" align="center" width="80">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="check(scope.row.id, scope.row.tenantId)"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
              width="90"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.disputeFlag == 0 ? true : false">{{
                  timestampReturnDate(scope.row.createTime, "datetime")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="remarks"
              label="来源途径"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.remarks"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    class="ellipsis"
                    v-if="scope.row.disputeFlag == 0 ? true : false"
                  >
                    {{ scope.row.remarks }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="accountStatusName"
              label="账号状态"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="source"
              label="备注"
              align="center"
              width="120"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.source"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div
                    class="ellipsis"
                    v-if="scope.row.disputeFlag == 0 ? true : false"
                  >
                    {{ scope.row.source }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="200"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="detailStudent(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  v-if="
                    scope.row.accountStatusName == '已开通' &&
                      $isShowModle('stu_aid_resetPassword')
                  "
                  @click="resetPass(scope.row.loginNum)"
                  >重置密码</el-button
                >
                <el-button
                  v-if="
                    scope.row.accountStatusName == '已开通' &&
                      $isShowModle('stu_aid_close')
                  "
                  type="text"
                  @click="closeAccountEvent(scope.row.id, scope.row.schoolId)"
                  >关闭账号</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>

    <dialogDiy
      :isShow="isShow"
      :dialogDiyData="dialogClassDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent" class="dia_class_opera">
        <!-- <div class="watchClass">查看班级</div> -->
        <div>
          <el-table
            :data="classTable"
            border
            style="width: 100%"
            align="center"
            max-height="350"
          >
            <el-table-column label="班级标题" align="center" prop="name">
            </el-table-column>
            <el-table-column
              label="助学分类"
              align="center"
              :formatter="regType"
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- <el-button class="closeClass" @click="closeEvent">关闭</el-button> -->
      </div>
    </dialogDiy>

    <el-dialog width="400px" class="compile" title="重置密码" :visible.sync="isShowVer">
      <div class="cont_pass">确定要重置该用户的密码？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowVer = false">取 消</el-button>
        <el-button type="primary" @click="enterEvent">确 定</el-button>
      </div>
    </el-dialog>

    <dialogDiy
      :isShow="passwordShow"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="passwordDialogDiyEvent"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="" />
        <p>确定要关闭该账号吗？</p>
        <div class="btn_area">
          <el-button @click="batchDelete">取消</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import Pagination from "../Pagination";
import dialogDiy from "../commonModule/dialogDiy";
import way from "../../api/encapsulation";
export default {
  name: "sourceManagement",
  components: {
    Pagination,
    dialogDiy
  },
  data() {
    return {
      activeName: "first",
      allSchoolData: [],
      allStationData: [],
      allClassData: [],
      schoolBatchData: [],
      majorData: [],
      formInline: {
        accountStatus: "",
        admissionBatch: "",
        cardId: "",
        disputeFlag: "",
        eduCategory: "",
        majorId: "",
        name: "",
        page: 1,
        pageSize: 10,
        schoolId: "",
        stationId: "",
        aidClassId: ""
        // tenantId: "",
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      loading: false,
      isShow: false,
      passwordShow: false,
      isShowVer: false,
      dialogDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "300px" }
      },
      dialogClassDiyData: {
        title: "查看班级",
        sizeStyle: { width: "430px", height: "500px" }
      },
      classTable: [],
      studentData: {}
    };
  },
  created() {
    this.getTenantDown();
    this.getClassDown();
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if (newVal.name == "sourceManagement") {
          this.getAidTableData();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    handleKeydown(event) {
      if (!this.isShow&&!this.passwordShow) {
        this.onSubmit()
      }
    },
    regType(row) {
      let typeVal = row.type == 1 ? '成考考前辅导' : ''
      let eduVal = row.eduCategory == '0' ? '专升本' : row.eduCategory == 1 ? '高起本' : '高起专'
      let val = row.isGeneral || row.eduCategory != '0' ? `${typeVal}/${eduVal}` : `${typeVal}/${eduVal}/${row.subjectName}`
      return val;
    },
    // 获取班级数据
    getClassDown() {
      this.$request.getClassDownVo({}, res => {
        if (res.code == 0) {
          this.allClassData = res.data;
        }
      });
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
        }
      });
    },
    // 监听学校数据变化
    changeSchool() {
      // 学校与批次、教学点联动
      this.formInline.name = null;
      this.formInline.cardId = null;
      this.formInline.admissionBatch = null;
      this.formInline.eduCategory = null;
      this.formInline.majorId = null;
      this.formInline.stationId = null;
      this.formInline.aidClassId = null;
      this.allStationData = [];
      this.allClassData = [];
      this.schoolBatchData = [];
      this.majorData = [];
      this.getGoSchoolData();
      this.getGoStationData();
      this.getClassDown();
    },
    // 获取入学批次数据
    getGoSchoolData() {
      // let obj = {
      //   tenantId: this.formInline.schoolId
      // };
      // this.$request.getGoSchool(obj, res => {
      //   if (res.code == 0) {
      //     this.schoolBatchData = res.data;
      //   }
      // });
      if (!this.formInline.schoolId) {
        this.Warn("先选择学校");
        return;
      }
      if (this.schoolBatchData.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId
      };
      this.$request.aidBatchDropDown(obj).then(res => {
        if (res.data.code == 0) {
          this.schoolBatchData = res.data.data;
        }
      });
    },
    // 监听批次数据变化
    changeAdmissionBatch() {
      // 批次与层次联动
      this.formInline.eduCategory = null;
      this.formInline.majorId = null;
      this.majorData = [];
    },
    // 获取教学点数据
    getGoStationData() {
      let obj = {
        tenantId: this.formInline.schoolId
      };
      this.$request.getTheDropDownBoxs(obj, res => {
        if (res.code == 0) {
          this.allStationData = res.data;
        }
      });
    },
    // 监听层次数据变化
    changeEduCategory() {
      // 层次与专业联动
      this.formInline.majorId = null;
      this.majorData = [];
      // this.getMajorDataEvent()
    },
    // 获取专业数据变化
    getMajorDataEvent() {
      if (!this.formInline.schoolId) {
        this.Warn("请选择学校");
        return;
      }
      // if (!this.formInline.admissionBatch) {
      //   this.Warn("请选择批次");
      //   return;
      // }
      // if (
      //   !(
      //     typeof this.formInline.eduCategory == "string" ||
      //     typeof this.formInline.eduCategory == "number"
      //   )
      // ) {
      //   this.Warn("请选择层次");
      //   return;
      // }
      if (this.majorData.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId,
        eduCategory: this.formInline.eduCategory,
        batch: this.formInline.admissionBatch
      };
      this.$request.aidMajorDropDown(obj).then(res => {
        if (res.data.code == 0) {
          this.majorData = res.data.data;
        }
      });
    },
    // 警告信息
    hasAbmEvent() {
      if (!this.formInline.schoolId) {
        this.Warn("请先选学校");
        return;
      }
    },
    hasMajEvent() {
      if (!this.formInline.schoolId) {
        this.Warn("请先选层次");
        return;
      }
    },
    // 提交按钮
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getAidTableData();
    },
    // 重置
    resetEvent() {
      this.formInline.accountStatus = "";
      this.formInline.admissionBatch = "";
      this.formInline.cardId = "";
      this.formInline.disputeFlag = "";
      this.formInline.eduCategory = "";
      this.formInline.majorId = "";
      this.formInline.name = "";
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.formInline.schoolId = "";
      this.formInline.stationId = "";
      this.formInline.aidClassId = "";
      this.allStationData = [];
      this.allClassData = [];
      this.schoolBatchData = [];
      this.majorData = [];
      this.getAidTableData();
    },
    // 查看班级
    check(valId, valTenantId) {
      this.isShow = !this.isShow;
      this.loading = true;
      let obj = {
        valId,
        valTenantId
      };
      this.$request.getAidClassList(obj, res => {
        this.loading = false;
        if (res.code == 0) {
          this.classTable = res.data;
          this.classTable.forEach((item, index) => {
            if (!item) this.classTable.splice(index, 1);
          });
        }
      });
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    // closeEvent() {
    //   this.isShow = false;
    // },
    handleSelectionChange(val) {
      // console.log(val)
      if (val.length > 0) {
        let idArr = val.map((item, index) => {
          return item.id;
        });
        this.idArr = idArr;
      } else {
        this.idArr = [];
      }
    },
    // 重置密码
    resetPass(valName) {
      console.log(valName);
      this.studentData = {
        username: valName
      };
      this.isShowVer = !this.isShowVer;
    },
    enterEvent() {
      this.loading = true;
      let obj = this.studentData;
      this.$request.resetStuPass(obj).then(res => {
        // console.log(res)
        this.loading = false;
        this.isShowVer = false;
        if (res.data.code == 0) {
          this.rest();
        }
      });
    },
    rest() {
      this.$confirm("密码已重置")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    // 关闭账号
    closeAccountEvent(valAidId, valTenantId) {
      this.passwordShow = !this.passwordShow;
      this.studentData = {
        aidId: valAidId,
        tenantId: valTenantId
      };
    },
    doEvent() {
      this.loading = true;
      let obj = this.studentData;
      this.$request.closeAccount(obj, res => {});
      this.loading = false;
      this.passwordShow = false;
      this.getAidTableData();
    },
    passwordDialogDiyEvent(val) {
      this.passwordShow = val.passwordShow;
    },
    batchDelete() {
      this.passwordShow = !this.passwordShow;
    },
    // 获取表格数据
    getAidTableData() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getAidClassStuPage(obj, res => {
        this.loading = false;
        if (res.code == 0) {
          // console.log(res)
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    // 学生详情
    detailStudent(row) {
      let { formInline } = row;
      this.$router.push({
        name: "studentDetails",
        query: {
          disputeFlag: row.disputeFlag,
          tenantId: row.tenantId,
          id: row.id
        }
      });
    },
    // 换页
    changePage(val) {
      // console.log(val)
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getAidTableData();
    }
  }
};
</script>

<style lang="less">
.sourceManagement {
  height: 100%;
  .elScroll {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .search_sList {
    background-color: #fff;
    padding: 28px 40px;
    .top_opera {
      display: flex;
      .el-form {
        flex: 4;
      }
      .btn_area {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .table_sList {
    box-sizing: border-box;
    padding: 30px 40px;
    margin-top: 10px;
    background-color: #fff;
    .opera_btnArea {
      margin-bottom: 20px;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      border-right: none;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    .el-pagination {
      text-align: right;
    }
  }
  .dia_opera {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    // .watchClass{
    //   width: 100%;
    //   text-align: left;
    // }
    .closeClass {
      margin: 5% 0 0 0;
      text-align: center;
    }
  }
  .dia_class_opera {
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
  }
}
</style>